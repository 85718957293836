import { Image } from 'antd'
import React from 'react'
import mini from '../../images/mini.webp'
import BtnComp from '../btn/BtnComp'
import { Flex, Rate } from 'antd';

const SectionMini = () => {
	return (
		<section className='relative bg-white py-16'>
			<div className='container mx-auto'>

				<div className='grid sd:grid-cols-2 xz:grid-cols-1 gap-5 border-b border-gray-200 pb-4'>

					<div className='sd:border-r border-gray-200 xz:border-r-0'>
						<Image src={mini} preview={false} alt='Аренда мини экскаватора' className='' />
					</div>
					<div className=''>
						<h2 className='sd:text-4xl xz:text-2xl font-semibold'>
							Аренда мини экскаватора Минск + Минская область
						</h2>
						<p className='text-sm font-light'>
							Мин.заказ: 8 часов 640 руб. +подача.
						</p>
						<p className='text-sm font-light'>
							Цена за 1 час от: 80 руб.
						</p>

						<div className='flex mt-6'>
							<div className='border-r border-orange-500 px-2'>
								<p className='font-light text-sm text-center'>
									Скидки постоянным клиентам
								</p>
							</div>
							<div className='border-r border-orange-500 px-2'>
								<p className='font-light text-sm text-center'>
									Скидки при заказе от 7 смен
								</p>
							</div>
							<div className='px-2'>
								<p className='font-light text-sm text-center'>
									Наличный и безналичный расчёт
								</p>
							</div>
						</div>

						<div className='mt-14 flex justify-end sd:pr-6 xz:pr-0'>
							<div className=''>
								<p className='text-3xl font-extrabold'>
									от 80 руб.
								</p>

								<div className=''>
									<BtnComp title2='Заказать' />
								</div>
							</div>
						</div>

						<div className=''>
							<Rate disabled defaultValue={5} />
							<p className='text-xs mt-2 font-light'>
								Оценка качества 5 (21 Голос)
							</p>
						</div>
					</div>
				</div>


				<div className='pt-5 grid sd:grid-cols-2 xz:grid-cols-1 gap-5'>
					<div className='sd:border-r border-gray-200 xz:border-r-0 sd:pl-12 pt-1 xz:pl-5'>
						<p className='sd:text-2xl xz:text-lg mb-4 font-semibold'>
							Характеристики
						</p>

						<ul className='list-disc font-light'>
							<li className='mb-2'>
								Длина, мм - 3418
							</li>
							<li className='mb-2'>
								Ширина, мм - 980
							</li>
							<li className='mb-2'>
								Высота, мм  - 2318
							</li>
							<li className='mb-2'>
								Эксплуатационная маса – 1587 кг
							</li>
							<li className='mb-2'>
								Высота выгрузки ковша – 2626 мм
							</li>
							<li className='mb-2'>
								Глубина копания – 2570 мм
							</li>
							<li className='mb-2'>
								Стрела – поворотная
							</li>
							<li className='mb-2'>
								Длина стрелы – 1680 мм
							</li>
						</ul>

					</div>

					<div className='sd:pl-6 xz:pl-5'>
						<p className='sd:text-2xl xz:text-lg mb-4 font-semibold'>
							Условия аренды
						</p>

						<ul className='list-decimal font-light'>
							<li className='mb-2'>
								Техника предоставляется с водителем-оператором.
							</li>
							<li className='mb-2'>
								Возможна аренда мини экскаватора с нашим топливом или с топливом клиента (будет дешевле).
							</li>
							<li className='mb-2'>
								Возможна оплата по безналичному (Б/Н) и наличному расчёту.
							</li>
							<li className='mb-2'>
								Подача экскаватора на объект оплачивается клиентом или бесплатно, если техника, в момент отправки, находится рядом с местом проведения работ (уточняйте у менеджера).
							</li>
							<li className='mb-2'>
								Время работы считается с момента прибытия экскаватора на объект клиента.
							</li>
							<li className='mb-2'>
								Мини-экскаватор выезжает по 100% предоплате или по договоренности.
							</li>
							<li className='mb-2'>
								Для юридических лиц схема заказа такая: подписываем договор и после выполнения работ предоставляем акт выполненных работ.
							</li>
							<li className='mb-2'>
								Простой рассчитывается по схеме: 50% от оговоренной стоимости часа.
							</li>
						</ul>
					</div>

				</div>
			</div>
		</section>
	)
}

export default SectionMini