
import React from 'react'
import img from '../../../images/service/1.webp'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'
import check from '../../../images/check3.svg'

const MontazhTransformPodstancij = () => {

	return (
		<main className='sd:py-16 xz:py-10'>

			<Helmet>
				<title>Монтаж трансформаторных подстанций | ООО «АмперСистемс»</title>
				<meta
					name="description"
					content="Профессиональный монтаж трансформаторных подстанций от ООО «АмперСистемс». Качественные и надежные решения для вашего бизнеса с гарантией безопасности и эффективности."
				/>
			</Helmet>



			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Монтаж трансформаторных подстанций
				</h1>

				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>


					<div className='sd:w-1/2 xz:w-full'>
						<ul className='sd:text-xl xz:text-lg mt-8'>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж ВВ-10кВ, ВМ-10кВ, ВН-10кВ, СР-10кВ, РЛНД 10кВ' className='' style={{ width: '25px' }} />
								<p className='ml-3'>
									Монтаж ВВ-10кВ, ВМ-10кВ, ВН-10кВ, СР-10кВ, РЛНД 10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж щитового оборудования в РУ 0,4 – 10кВ' className='w-6' />
								<p className='ml-3'>
									Монтаж щитового оборудования в РУ 0,4 – 10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж силовых трансформаторов' className='w-6' />
								<p className='ml-3'>
									Монтаж силовых трансформаторов
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж оборудования мачтовых подстанций' className='w-6' />
								<p className='ml-3'>
									Монтаж оборудования мачтовых подстанций
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж, замена проходных изоляторов' className='w-6' />
								<p className='ml-3'>
									Монтаж, замена проходных изоляторов
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Фазировка КЛ 0,4 – 10кВ' className='w-6' />
								<p className='ml-3'>
									Фазировка КЛ 0,4 – 10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж заземления' className='w-6' />
								<p className='ml-3'>
									Монтаж заземления
								</p>
							</li>
						</ul>

					</div>


					<div className='sd:w-1/2 xz:w-full sd:ml-10 xz:ml-0'>
						<Image src={img} alt='Инжиниринговые услуги' preview={false} className='rounded-md shadow-xl' />
					</div>
				</div>


				<Info />
				<BtnComp name='Итересует стоимость и сроки инжиниринговых услуг' />
			</section>
		</main>
	)
}

export default MontazhTransformPodstancij