
import React from 'react'
import img from '../../../images/services/2.webp'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'
import check from '../../../images/check3.svg'

const ProektElectro = () => {


	return (
		<main className='sd:py-16 xz:py-10'>

			<Helmet>
				<title>Проектирование электроснабжения | ООО «АмперСистемс»</title>
				<meta
					name="description"
					content="Проектирование электроснабжения для различных объектов – от жилых домов до промышленных предприятий. Услуги высокого качества от ООО «АмперСистемс»."
				/>
			</Helmet>


			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Проектирование электроснабжения
				</h1>

				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>

					{/* <div className='sd:w-1/2 xz:w-full sd:mr-6 xz:mr-0 sd:mt-0 xz:mt-8'>
						<p className='sd:text-lg xz:text-base'>
							Одним из самых Важных этапов подключения электричества является разработка проектно-сметной документации. Если проект разработан качественно, это гарантирует понимание между заказчиком,  подрядчиком, поставщиком, контролирующими органами, как и что должно быть сделано. Заказчик заблаговременно избавляет себя от перерасхода материалов, а так же трат на завышенные объемы работ.
						</p>

					</div> */}
					<div className='sd:w-1/2 xz:w-full'>
						<ul className='sd:text-xl xz:text-base mt-8'>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Проектирование наружных сетей' className='' style={{ width: '25px' }} />
								<p className='ml-3'>
									Проектирование наружных сетей электроснабжения (ТП, КТП, МТП, ВЛ 0,4кВ – 10кВ, КЛ 0,4кВ – 10кВ и т.д.)
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Проектирование внутренних сетей' className='w-6' />
								<p className='ml-3'>
									Проектирование внутренних сетей электроснабжения (жилой дом, квартира, производство, офис и т.д.)
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Разработка разделов РЗА, ТМ, ЭМ, ЭС, ЭГ, ЭО' className='w-6' />
								<p className='ml-3'>
									Разработка разделов РЗА, ТМ, ЭМ, ЭС, ЭГ, ЭО
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Проектирование электроснабжения' className='w-6' />
								<p className='ml-3'>
									Проектирование электроснабжения для нужд отопления по льготным тарифам
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Проектирование зарядных станций электромобилей' className='w-6' />
								<p className='ml-3'>
									Проектирование зарядных станций электромобилей
								</p>
							</li>
						</ul>

					</div>

					<div className='sd:w-1/2 xz:w-full sd:ml-6 xz:ml-0'>
						<Image src={img} alt='Проектирование электроснабжения' preview={false} className='rounded-md shadow-xl' />
					</div>
				</div>

				<Info />

				<BtnComp name='Итересует стоимость и сроки проектирования электроснабжения' />
			</section>

		</main>
	)
}

export default ProektElectro