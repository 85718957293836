import React from 'react'
import { SectionGreyMain } from '../../components/main/SectionGreyMain'
import { useScreens } from '../../constants/Constants'
import { FloatButton } from 'antd'
import { Helmet } from "react-helmet"
import { Services } from '../../components/main/Services'
import BtnComp from '../../components/btn/BtnComp'
import SectionMiniMain from '../../components/main/SectionMiniMain'

export const MainPage = () => {
	const screens = useScreens()

	return (
		<main className={``}>
			<div className='w-full bg-cover fon bg-center' />
			<Helmet>
				<title>Монтаж и обслуживание электроустановок</title>
				<meta name="description" content="У нас вы можете заказать монтаж и обслуживание электроустановок высокого качества. Профессиональные услуги по доступным ценам." />
			</Helmet>

			<section className='sd:min-h-[80vh] xz:min-h-[60vh] relative text-white sd:pt-20 xz:pt-0'>
				<div className='container mx-auto'>
					<div className='pt-6'>
						<h1 className='sd:text-6xl xz:text-xl xy:text-2xl font-black uppercase tracking-wide text-shadow'>
							Монтаж и обслуживание <span className=''>электроустановок</span>
						</h1>
						<p className='mt-6 sd:text-2xl xz:text-base sd:max-w-xl xz:max-w-full'>
							Оказываем полный комплекс услуг на любых объектах под ключ в<span className='font-bold'> Минске и Минской области</span>
						</p>

						<div className='mt-16 pl-1'>
							<BtnComp title2='Получить консультацию' name='Получить консультацию' />
						</div>
					</div>

					<div className='absolute left-1/2 -translate-x-1/2 bottom-0'>
						<p className='uppercase'>
							Минск
						</p>
					</div>

				</div>
			</section>

			<Services />

			<SectionMiniMain />

			<SectionGreyMain />


			<FloatButton.BackTop />
		</main>
	)
}
