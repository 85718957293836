import React from 'react'
import { motion } from "framer-motion"
import { titleAnimation2, titleAnimation } from '../../constants/animation/AnimationConst'

export const SectionGreyMain = () => {
	return (
		<section className='bg-[#313846] text-white py-10 relative'>
			<motion.div
				className='container mx-auto'
				initial="hidden"
				whileInView="visible"
			>
				<motion.h4
					className='text-2xl'
					variants={titleAnimation}
				>
					ООО "АмперСистем"
				</motion.h4>
				<p
					className='font-light leading-6 sd:text-lg xy:text-base text-justify tracking-wide'
					variants={titleAnimation2}
				>
					Наша компания ООО "АмперСистем" - это команда профессионалов, специализирующихся на электротехнических решениях для промышленных и гражданских объектов. Мы предлагаем полный спектр услуг в сфере электроэнергетики - от проектирования и монтажа до обслуживания и ремонта.
					Одним из наших преимуществ является опыт нашей команды. Наши специалисты имеют большой опыт работы в электроэнергетической отрасли и постоянно совершенствуют свои знания и навыки. Это позволяет нам предлагать нашим клиентам самые современные и эффективные решения.
					Кроме того, мы уделяем особое внимание качеству используемых материалов и оборудования. Мы работаем только с проверенными поставщиками, чтобы гарантировать надежность и долговечность наших решений.
					Мы готовы предложить нашим клиентам индивидуальный подход, учитывающий все особенности каждого проекта. Мы стремимся к тому, чтобы наши решения были максимально эффективными и экономически выгодными для наших клиентов.
					Наша компания имеет все необходимые сертификаты и лицензии, что гарантирует надежность и качество наших услуг.
					Если вы ищете надежного и профессионального партнера в сфере электроэнергетики, то обратитесь к нам. Мы готовы предложить вам лучшие решения, чтобы обеспечить надежное и безопасное электроснабжение вашего объекта.
				</p>
			</motion.div>
		</section>
	)
}
