
import React from 'react'
import img from '../../../images/service/8.webp'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'
import check from '../../../images/check3.svg'

const MontazhVozdushKabelLinij = () => {

	return (
		<main className='sd:py-16 xz:py-10'>

			<Helmet>
				<title>Монтаж воздушных, кабельных линий электропередач 0,4 – 10кВ</title>
				<meta
					name="description"
					content="Профессиональный монтаж воздушных и кабельных линий электропередач напряжением 0,4 – 10кВ. Качественные услуги по проектированию, установке и обслуживанию линий электропередач для обеспечения надежного энергоснабжения."
				/>
			</Helmet>

			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Монтаж воздушных, кабельных линий электропередач 0,4 – 10кВ
				</h1>
				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>
					<div className='sd:w-1/2 xz:w-full'>
						<ul className='sd:text-xl xz:text-lg mt-8'>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Замена изношенного провода на СИП' className='' style={{ width: '25px' }} />
								<p className='ml-3'>
								Замена изношенного провода на СИП
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Установка, замена опор 0,4 – 10кВ' className='w-6' />
								<p className='ml-3'>
								Установка, замена опор 0,4 – 10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Замена изоляторов 0,4 -10кВ' className='w-6' />
								<p className='ml-3'>
								Замена изоляторов 0,4 -10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Ремонт воздушных линий 0,4 -10кВ' className='w-6' />
								<p className='ml-3'>
								Ремонт воздушных линий 0,4 -10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Ремонт кабельных линий 0,4 -10кВ' className='w-6' />
								<p className='ml-3'>
								Ремонт кабельных линий 0,4 -10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж соединительных, концевых муфт' className='w-6' />
								<p className='ml-3'>
								Монтаж соединительных, концевых муфт
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж заземления' className='w-6' />
								<p className='ml-3'>
								Монтаж заземления
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Прокладка кабельных линий в земле' className='w-6' />
								<p className='ml-3'>
								Прокладка кабельных линий в земле, лотках, трубах.
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Прокол под дорогой' className='w-6' />
								<p className='ml-3'>
								Прокол под дорогой
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Благоустройство после раскопок' className='w-6' />
								<p className='ml-3'>
								Благоустройство после раскопок
								</p>
							</li>
						</ul>

					</div>


					<div className='sd:w-1/2 xz:w-full sd:ml-10 xz:ml-0'>
						<Image src={img} alt='Инжиниринговые услуги' preview={false} className='rounded-md shadow-xl' />
					</div>
				</div>


				<Info />
				<BtnComp name='Итересует стоимость и сроки инжиниринговых услуг' />
			</section>
		</main>
	)
}

export default MontazhVozdushKabelLinij