import React from 'react';

const ArticleMini = () => {
    return (
        <article className='relative bg-white sd:py-9 xz:py-2'>
            <div className='container mx-auto'>
                <h3 className='sd:text-2xl xz:text-xl'>
                    Для чего чаще всего используют мини экскаватор
                </h3>
                <p className='mt-6 sd:text-base xz:text-sm font-light'>
                    Мини экскаваторы представляют собой универсальное техническое средство, способное выполнять широкий спектр задач в самых разнообразных условиях. Они находят применение во множестве отраслей благодаря своей компактности и маневренности. Рассмотрим основные области использования мини экскаваторов:
                    <br/><br/>
                    - Разборка зданий: Мини экскаваторы идеально подходят для разборки зданий благодаря своей компактности и способности работать в ограниченных пространствах. Они могут эффективно справляться с демонтажем стен и других конструкций.
                    <br/><br/>
                    - Рытье шахт и тоннелей: В строительстве и гражданском инжиниринге мини экскаваторы используются для рытья шахт и тоннелей. Их маневренность и способность работать на ограниченной территории позволяют осуществлять работы даже в самых труднодоступных местах.
                    <br/><br/>
                    - Работы в котлованах и труднодоступных местах: Мини экскаваторы необходимы для выполнения различных работ в котлованах, где большие машины не могут проходить. Они могут рыть траншеи, обеспечивать поддержку стен и выполнять другие строительные операции.
                    <br/><br/>
                    - Работы в помещениях с низкими потолками: Благодаря своим компактным размерам, мини экскаваторы идеально подходят для работы в помещениях с низкими потолками, где стандартные экскаваторы не могут работать.
                    <br/><br/>
                    - Работы на мягких грунтах: Мини экскаваторы успешно применяются при работе на мягких грунтах благодаря своей низкой нагрузке на почву. Они могут выполнять копку и выемку на мягких или неустойчивых грунтах без повреждения окружающей среды.
                    <br/><br/>
                    - Работы по благоустройству: Для благоустройства городских территорий мини экскаваторы используются для создания ландшафтных элементов, копки канав и устройства дренажных систем.
                    <br/><br/>
                    - Копание траншей под коммуникации: В строительстве и ремонте коммуникаций, таких как трубопроводы и кабели, мини экскаваторы обеспечивают точное и аккуратное копание траншей в труднодоступных местах.
                    <br/><br/>
                    Мини экскаваторы отличаются своей универсальностью и могут быть адаптированы под различные задачи, что делает их важным инструментом в строительной и гражданской инфраструктуре.
                </p>
            </div>
        </article>
    );
}

export default ArticleMini;
