
import React from 'react'
import img from '../../../images/services/4.webp'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'
import check from '../../../images/check3.svg'

const ElektroIzmeren = () => {

	return (
		<main className='sd:py-16 xz:py-10'>
			<Helmet>
				<title>Электрофизические измерения | ООО «АмперСистемс»</title>
				<meta
					name="description"
					content="ООО «АмперСистемс» предлагает профессиональные услуги по электрофизическим измерениям. Наши специалисты осуществляют широкий спектр измерений, включая проверку изоляции, заземления, параметров электрических цепей и других параметров электрооборудования. Мы гарантируем точность измерений и предоставляем подробные отчеты."
				/>
			</Helmet>


			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Электрофизические измерения
				</h1>

				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>

					{/* <div className='sd:w-1/2 xz:w-full sd:mr-6 xz:mr-0 sd:mt-0 xz:mt-8'>
						<p className='sd:text-lg xz:text-base'>
							В распоряжении нашей организации имеется лаборатория для выполнения электрофизических измерений. Наши представители в кратчайшие сроки появятся на Вашем объекте, проведут необходимые измерения и выдадут технический отчет с результатами
						</p>
					</div> */}

					<div className='sd:w-1/2 xz:w-full'>
						<ul className='sd:text-xl xz:text-lg mt-8'>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Измерения при проверке устройств защитного отключения' className='' style={{ width: '25px' }} />
								<p className='ml-3'>
								Измерения при проверке устройств защитного отключения, управляемых дифференциальным током.
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Измерение параметров автоматических выключателей' className='w-6' />
								<p className='ml-3'>
								Измерение параметров автоматических выключателей
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Испытание цепи "фаза-нуль"' className='w-6' />
								<p className='ml-3'>
								Испытание цепи "фаза-нуль" (цепи зануления) в электроустановках до 1 кВ с глухим заземлением нейтрали
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Измерение сопротивления заземляющего устройства' className='w-6' />
								<p className='ml-3'>
								Измерение сопротивления заземляющего устройства
								</p>
							</li>
							
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Проверки соединений заземлителей с заземляемыми элементами' className='w-6' />
								<p className='ml-3'>
								Проверки соединений заземлителей с заземляемыми элементами
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Измерения сопротивления изоляции силовых и осветительных сетей' className='w-6' />
								<p className='ml-3'>
								Измерения сопротивления изоляции силовых и осветительных сетей
								</p>
							</li>
						</ul>

					</div>

					<div className='sd:w-1/2 xz:w-full sd:ml-6 xz:ml-0'>
						<Image src={img} alt='Электромонтажные работы' preview={false} className='rounded-md' />
					</div>
				</div>

				<Info />

				<BtnComp name='Итересует стоимость и сроки электрофизических измерений' />
			</section>

		</main>
	)
}

export default ElektroIzmeren