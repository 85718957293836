// import { Button } from 'antd'
import React from 'react'
import { motion } from "framer-motion"

export const FooterComp = () => {

  const titleAnimation = {
    hidden: {
      y: 100,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.7
      }
    }
  }
  const titleAnimation2 = {
    hidden: {
      y: -100,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.7
      }
    }
  }

  const adressAnimation = {
    hidden: {
      y: 100,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 1.7
      }
    }
  }

  return (
    <footer className='pt-10 bg-slate-50 h-[50vh] overflow-hidden relative'>
      <div className='container mx-auto'>
        <div className='flex flex-col justify-between'>
          <motion.div
            initial="hidden"
            whileInView="visible"
            className='flex'
          >
            <motion.p
              variants={titleAnimation}
              className='text-2xl font-extrabold uppercase mr-1'
            >
              ООО
            </motion.p>
            <motion.p
              variants={titleAnimation2}
              className='text-2xl font-extrabold uppercase'
            >
              АмперСистем
            </motion.p>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            className='flex flex-col justify-between'
          >
            <motion.p
              variants={adressAnimation}
              className='font-light'
            >
              Выполняем любые работы по строительству, обслуживанию и ремонту элекстрических установок и оборудования.
            </motion.p>
            <motion.div
              variants={adressAnimation}
              className='flex justify-between items-center'>
              <div className='font-extralight'>
                <a href="https://yandex.by/maps/157/minsk/house/Zk4YcAFiS0YBQFtpfXVzdnVhZA==/?ll=27.662235%2C53.927920&z=16.81"
                  title="Наш адрес"
                  target='_blank'
                  rel="noreferrer"
                  className='mb-3'
                >
                  г.Минск, ул. Ф.Скорины, д.15, изолированное помещение №1, каб.511
                </a>
               
                <a href='tel:80297777125' className='mt-4 block text-xl font-bold'>+375 29 7777-125</a>
              </div>
            </motion.div>
          </motion.div>

          <div className='flex items-center absolute bottom-1 border-t pt-1'>
            <p className="
              font-poppins
              font-light
              text-center
              sd:text-[14px]
              xz:text-[10px]
              text-gray-500
              "
            >
              Copyright Ⓒ 2023. Разработка и продвижение <a href="https://vi-tech.by" className="text-blue-500" rel="noreferrer" target="_blank">VI:TECH</a>. Информация на сайте не является публичной офертой и предоставляется исключительно в информационных целях.
            </p>
          </div>
        </div>
      </div>
    </footer >
  )
}
