import React, { useState } from 'react'
import { Button, Divider } from 'antd'
// import { motion } from "framer-motion"
// import { Link as LinkScroll } from 'react-scroll'
import * as icons from '@ant-design/icons'
import { DrawerComp } from '../../drawer/DrawerComp'
import { Link } from 'react-router-dom'

export const MenuMobil = ({ onClose }) => {
	const [open, setOpen] = useState(false)
	const [placement, setPlacement] = useState('')
	const [title, setTitle] = useState('')
	const [isActiveForm, setIsActiveForm] = useState({
		tel: false,
		date: false,
		menu: false,
	})

	const showDrawer = (position, title, str) => {
		setOpen(true)
		setPlacement(position)
		setTitle(title)
		switch (str) {
			case 'tel': setIsActiveForm(prev => ({ ...prev, tel: true, date: false, menu: false }))
				break
			case 'date': setIsActiveForm(prev => ({ ...prev, tel: false, date: true, menu: false }))
				break
		}
	}


	return (
		<div className='h-full'>
			<nav className='h-full'>
				<ul className='h-full flex flex-col justify-between items-start text-sm cursor-pointer'>
					<li>
						<Link to='/'
							className="cursor-pointer font-bold"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Главная страница
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/inzhiniringovye-uslugi'
							className="cursor-pointe"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Инжиниринговые услуги
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/proektirovanie-elektrosnabzheniya'

							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Проектирование электроснабжения
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/elektromontazhnye-raboty'
							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Электромонтажные работы
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/elektrofizicheskie-izmereniya'
							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Электрофизические измерения
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/podklyuchenie-elektrichestva'
							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Подключение электричества
						</Link>
					</li>

					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/elektricheskoe-otoplenie'
							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Подключение электрического отопления
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/obsluzhivanie-elektroustanovok'
							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Обслуживание электроустановок 0,4-10кВ
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/montazh-transformatornyh-podstancij'
							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Монтаж трансформаторных подстанций
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/uslugi/montazh-vozdushnyh-kabelnyh-linij'
							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Монтаж воздушных, кабельных линий электропередач 0,4 – 10кВ
						</Link>
					</li>

					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />

					<li>
						<Link to='/arenda-mini-ekskavatora'
							className="cursor-pointer"
							onClick={onClose}
							style={{ color: '#eab308' }}
						>
							Аренда мини экскаватора
						</Link>
					</li>

					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/o-kompanii'
							className="cursor-pointer font-semibold"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							О компании
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li>
						<Link to='/kontakty'
							className="cursor-pointer font-semibold"
							onClick={onClose}
							style={{ color: '#000' }}
						>
							Контакты
						</Link>
					</li>
					<Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
					<li className='mb-3 mt-5'>
						<Button
							type='text'
							onClick={() => showDrawer('top', 'Заказать звонок', 'tel')}
							style={{ paddingLeft: '0px' }}
						>
							<icons.ShakeOutlined /> Заказать звонок
						</Button>
					</li>
					<li className='mb-3'>
						<Button
							type='text'
							onClick={() => showDrawer('right', 'Заказать на дату', 'date')}
							style={{ paddingLeft: '0px' }}
						>
							<icons.CalendarOutlined /> Заказать на дату
						</Button>
					</li>
					{/* <li className=''>
						<Button
							type='text'
							style={{ color: 'rgb(234,88,12', paddingLeft:'0px' }}
						>
							<a href='tel:80290000000'
							>
								<icons.PhoneOutlined className='rotate-90 animate-pulse' /><span className='uppercase ml-2'>Вызвать аварийную бригаду</span>
							</a>
						</Button>
					</li> */}

				</ul>
			</nav>
			<DrawerComp open={open} placement={placement} title={title} setOpen={setOpen} isActiveForm={isActiveForm} />
		</div>
	)
}
