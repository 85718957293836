import React from 'react'
import { Helmet } from "react-helmet"

export const ContactPage = () => {
	return (
		<section className='pt-10'>
			<Helmet>
				<title>{'Контакты компании ООО АмперСистем'}</title>
				<meta name="description" content={'Контактная информация компании ООО АмперСистем: адрес, телефон, email, время работы.'} />
				<meta name="keywords" content={'ООО АмперСистем, контакты, адрес, телефон, email, время работы'} />
				<meta property="og:title" content={'Контакты компании ООО АмперСистем'} />
				<meta property="og:description" content={'Контактная информация компании ООО АмперСистем: адрес, телефон, email, время работы.'} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://ampersystem.by/kontakty" />
				<meta property="og:image" content="" />
				<meta name="twitter:card" content="" />
				<meta name="twitter:title" content={'Контакты компании ООО АмперСистем'} />
				<meta name="twitter:description" content={'Контактная информация компании ООО АмперСистем: адрес, телефон, email, время работы.'} />
				<meta name="twitter:image" content="" />
			</Helmet>


			<div className='container mx-auto sd:text-center xz:text-left'>
				<h1 className='text-2xl uppercase font-black mb-6'>
					Наши контакты
				</h1>
				<div className='text-lg font-extralight flex flex-col justify-between xz:items-start sd:items-center'>
					<p className='mb-1 font-semibold'>Адрес:</p>
					<a href="https://yandex.by/maps/157/minsk/house/Zk4YcAFiS0YBQFtpfXVzdnVhZA==/?ll=27.662235%2C53.927920&z=16.81"
						title="Наш адрес"
						target='_blank'
						rel="noreferrer"
						className='mb-3'
					>
						г.Минск, ул. Ф.Скорины, д.15, изолированное помещение №1, каб.511
					</a>
					<p className='mb-1 font-semibold'>Реквизиты:</p>
					<p className=''>
						УНП 193680826<br />
						р/с BY88 ALFA 3012 2D23 6300 1027 0000<br />
						в ЗАО «Альфа-Банк»<br />
						Адрес банка: 220013, г.Минск, ул. Сурганова, 43-47<br />
						БИК: ALFABY2X<br />
						УНП: 101541947<br />
						ОКПО 37526626<br />

					</p>
					<p className='mb-1 font-semibold'>Почта:</p>
					<a
						href="mailto:info@ampersystem.by"
						title="Наша почта"
						className='mb-3'
					>
						info@ampersystem.by
					</a>
					<p className='mb-1 font-semibold'>Телефон:</p>
					<a href='tel:80297777125'>+375 29 7777-125</a>
				</div>


			</div>

			<div className='mt-10'>
				<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A28aeb1ff9845318ae16acf877c292d1fe0e58a38c7d374e4e57800479d21c5de&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
			</div>
		</section>
	)
}
