
import React from 'react'
import img from '../../../images/services/6.webp'
import check from '../../../images/check3.svg'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'


const ElektrichOtopl = () => {

	return (
		<main className='sd:py-16 xz:py-10'>
			<Helmet>
				<title>Электрическое отопление под ключ | ООО «АмперСистемс»</title>
				<meta
					name="description"
					content="ООО «АмперСистемс» предлагает комплексные решения по установке и обслуживанию электрических систем отопления 'под ключ'. Мы осуществляем проектирование, поставку оборудования, монтаж и настройку системы отопления, а также предоставляем гарантийное и послегарантийное обслуживание. Наши опытные специалисты подберут оптимальное решение с учетом особенностей вашего объекта и ваших потребностей, обеспечивая надежную и эффективную работу системы отопления."
				/>
			</Helmet>

			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Электрическое отопление под ключ
				</h1>

				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>

					{/* <div className='sd:w-1/2 xz:w-full sd:mr-6 xz:mr-0 sd:mt-0 xz:mt-8'>
						<p className='sd:text-lg xz:text-base'>
							Наша организация готова оказать услуги по подключению электрического отопления для частного жилого дома под ключ (Разработка проекта электроснабжения, электромонтажные работы с установкой счетчика электрической энергии, проведение электрофизических измерений, сдача в эксплуатацию и подключение к сети электроснабжения)
						</p>
					</div> */}


					<div className='sd:w-1/2 xz:w-full'>
						<ul className='sd:text-xl xz:text-lg mt-8'>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Разработка проектной документации для электрического отопления' className='' style={{ width: '25px' }} />
								<p className='ml-3'>
								Разработка проектной документации для электрического отопления по льготным тарифам
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Электрофизические измерения (ЭФИ)' className='w-6' />
								<p className='ml-3'>
								Электрофизические измерения (ЭФИ)
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Расчет требуемой мощности для отопления дома' className='w-6' />
								<p className='ml-3'>
								Расчет требуемой мощности для отопления дома
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж конвекторов, бойлеров, котлов' className='w-6' />
								<p className='ml-3'>
								Монтаж конвекторов, бойлеров, котлов
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Сдача объектов в РЭС, Энергонадзор, Энергосбыт' className='w-6' />
								<p className='ml-3'>
								Сдача объектов в РЭС, Энергонадзор, Энергосбыт 
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж заземления' className='w-6' />
								<p className='ml-3'>
								Монтаж заземления
								</p>
							</li>
						
						</ul>

					</div>

					<div className='sd:w-1/2 xz:w-full sd:ml-6 xz:ml-0'>
						<Image src={img} alt='Электромонтажные работы' preview={false} className='rounded-md' />
					</div>
				</div>

				<Info />


				<BtnComp name='Итересует стоимость и сроки электрического отопления' />
			</section>

		</main>
	)
}

export default ElektrichOtopl