import React from 'react'
import { InfoCircleTwoTone } from '@ant-design/icons'

export const Info = () => {
	return (
		<>
			<p className='sd:text-lg xz:text-base text-gray-600 mt-10'>
				<InfoCircleTwoTone className='' />{" "}Точную стоимость и сроки Вы можете узнать, позвонив по телефону <a href='tel:80297777125' className='underline font-bold xz:block sd:inline sd:text-lg xz:text-xl'>+375 29 7777-125</a> или отправив нам сообщение (кнопка ниже). Мы выполняем работы как под ключ, так и частями. Оплата производится двумя способами: наличным и безналичным расчетом (с заключением договора). Гарантируем, что дешевле Вы не найдете.
			</p>
		</>
	)
}
