import React from 'react'
import mini from '../../images/mini.webp'
import { Image } from 'antd'
import { Link } from 'react-router-dom'

const SectionMiniMain = () => {
	return (
		<section className='relative bg-white py-10'>
			<div className='container mx-auto'>

				<div className='sd:p-8 xz:p-3 text-center'>
					<h4 className='sd:text-5xl xz:text-3xl font-extrabold'>
						Аренда мини экскаватора Минск + Минская область
					</h4>

					<div className='mt-9'>
						<Image src={mini} preview={false} alt='Аренда мини экскаватора' className='' />
					</div>

					<div className='mt-10 flex justify-end'>
						<Link to='/arenda-mini-ekskavatora'
							className="cursor-pointer font-semibold text-xl border rounded-md px-5 py-1.5"
							style={{ color: '#000' }}
						>
							Узнать подробнее
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SectionMiniMain