
import React from 'react'
import img from '../../../images/service/13.webp'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'
import check from '../../../images/check3.svg'

const ObsluzhivElektroustanovok = () => {

	return (
		<main className='sd:py-16 xz:py-10'>

			<Helmet>
				<title>Обслуживание электроустановок 0,4-10кВ, ответственный за электрохозяйство</title>
				<meta
					name="description"
					content="Профессиональное обслуживание электроустановок напряжением 0,4-10 кВ. Ответственный за электрохозяйство, квалифицированные специалисты, обеспечение надежной и безопасной работы."
				/>
			</Helmet>


			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Обслуживание электроустановок 0,4-10кВ, ответственный за электрохозяйство
				</h1>

				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>



					<div className='sd:w-1/2 xz:w-full'>
						<ul className='sd:text-xl xz:text-lg mt-8'>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Оперативные переключения в сетях 0,4 – 10кВ' className='' style={{ width: '25px' }} />
								<p className='ml-3'>
									Оперативные переключения в сетях 0,4 – 10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Аварийный ремонт любой сложности' className='w-6' />
								<p className='ml-3'>
									Аварийный ремонт любой сложности
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Текущий ремонт электроустановок согласно графика' className='w-6' />
								<p className='ml-3'>
									Текущий ремонт электроустановок согласно графика
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Взаимодействие с диспетчерскими пунктами РЭС' className='w-6' />
								<p className='ml-3'>
									Взаимодействие с диспетчерскими пунктами РЭС
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Права оперативных переговоров' className='w-6' />
								<p className='ml-3'>
									Права оперативных переговоров
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Подготовка объектов к ОЗП' className='w-6' />
								<p className='ml-3'>
									Подготовка объектов к ОЗП
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Имеем собственные поверенные СИЗ' className='w-6' />
								<p className='ml-3'>
									Имеем собственные поверенные СИЗ
								</p>
							</li>
						</ul>

					</div>


					<div className='sd:w-1/2 xz:w-full sd:ml-10 xz:ml-0'>
						<Image src={img} alt='Инжиниринговые услуги' preview={false} className='rounded-md shadow-xl' />
					</div>
				</div>


				<Info />
				<BtnComp name='Итересует стоимость и сроки инжиниринговых услуг' />
			</section>
		</main>
	)
}

export default ObsluzhivElektroustanovok