import React from 'react'
import { Helmet } from "react-helmet"
import SectionMini from '../../components/main/SectionMini'
import ArticleMini from '../../components/main/ArticleMini'

const ArendaMiniEkskavatora = () => {
	return (
		<main className=''>
			<div className='w-full bg-cover fon2 bg-center' />
			<Helmet>
				<title>{'Аренда мини экскаватора в Минске и Минской области'}</title>
				<meta name="description" content={'Аренда мини экскаватора в Минске и Минской области. Услуги по аренде маленьких экскаваторов для строительства и ремонтных работ. Заказать мини экскаватор с оператором. Доступные цены и качественное обслуживание.'} />
				<meta name="keywords" content={'аренда мини экскаватора, услуги мини экскаватора, заказать мини экскаватор, маленький экскаватор аренда, аренда мини экскаватора минская область, мини экскаватор аренда'} />
				<meta property="og:title" content={'Аренда мини экскаватора в Минске и Минской области'} />
				<meta property="og:description" content={'Аренда мини экскаватора в Минске и Минской области. Услуги по аренде маленьких экскаваторов для строительства и ремонтных работ. Заказать мини экскаватор с оператором. Доступные цены и качественное обслуживание.'} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://ampersystem.by/arenda-mini-ekskavatora" />
				<meta property="og:image" content="" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={'Аренда мини экскаватора в Минске и Минской области'} />
				<meta name="twitter:description" content={'Аренда мини экскаватора в Минске и Минской области. Услуги по аренде маленьких экскаваторов для строительства и ремонтных работ. Заказать мини экскаватор с оператором. Доступные цены и качественное обслуживание.'} />
				<meta name="twitter:image" content="" />
			</Helmet>


			<section className='relative min-h-[70vh] sd:pt-16 xz:pt-7'>

				<div className='container mx-auto text-white'>

					<h1 className='sd:text-6xl xz:text-3xl text-center font-semibold text-shadow'>
						Аренда <span className='sd:inline xz:block sd:my-0 xz:my-3 font-bold'>мини экскаватора</span> <span className='sd:block xz:inline sd:mt-6 xz:mt-0'>в Минске и Минской области</span>
					</h1>

					<p className='mt-10 sd:text-2xl xz:text-base text-center'>
						Выберите нашу компанию для <span className='font-bold text-yellow-500'>аренды мини экскаватора</span> в Минске и Минской области, если вам нужны надежные и современные машины для строительства и ремонтных работ. Мы предлагаем мини экскаватор с оператором по доступным ценам.
					</p>
				</div>
			</section>

			<SectionMini />

			<ArticleMini/>
		</main>
	)
}

export default ArendaMiniEkskavatora