
import React from 'react'
import img from '../../../images/services/3.webp'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'
import check from '../../../images/check3.svg'

const data = [
	{ work: "Штроба в бетон/кирпич", unit: "м/п", cost: 1.7 },
	{ work: "Штроба в газосиликате", unit: "м/п", cost: 1.5 },
	{ work: "Подрозетники в бетон/кирпич", unit: "шт", cost: 5 },
	{ work: "Подрозетники в газосиликате", unit: "шт", cost: 3 },
	{ work: "Монтаж ТВ канала в блоке", unit: "шт", cost: 15 },
	{ work: "Монтаж ТВ канала в бетон/кирпич", unit: "шт", cost: 20 },
	{ work: "Монтаж распред.коробки наружной", unit: "шт", cost: 10 },
	{ work: "Монтаж распред. коробки внутренней", unit: "шт", cost: 12 },
	{ work: "Перенос ввода", unit: "услуга", cost: 30 },
	{ work: "Монтаж кабеля 3х1.5мм/3х2.5мм", unit: "м/п", cost: 0.8 },
	{ work: "Монтаж кабеля в гофре/кабель канале", unit: "м/п", cost: 1.0 },
	{ work: "Монтаж кабеля 3х4мм/3х6мм", unit: "м/п", cost: 1.7 },
	{ work: "Монтаж кабеля 2х0,75мм для LED", unit: "м/п", cost: 0.7 },
	{ work: "Монтаж кабеля UTP интернет", unit: "м/п", cost: 0.7 },
	{ work: "Монтаж оптики патч-корда", unit: "м/п", cost: 1.7 },
	{ work: "Монтаж тёплого пола", unit: "м/п", cost: 5 },
	{ work: "Монтаж щита в бетоне накладной", unit: "шт", cost: 20 },
	{ work: "Монтаж щита газосилик/кирпич внутр.", unit: "шт", cost: 40 },
	{ work: "Подключение автоматики", unit: "модуль", cost: 5 },
	{ work: "Установка розеток/выключ.", unit: "шт", cost: 2 },
	{ work: "Установка интернет розетки", unit: "шт", cost: 3 },
	{ work: "Установка регуляторов", unit: "шт", cost: 7 },
	{ work: "Установка бра/светильников", unit: "шт", cost: 6 },
	{ work: "Раскопка траншей вручную до 0.70м", unit: "м/п", cost: 8 },
];

const Elektromontazh = () => {



	return (
		<main className='sd:py-16 xz:py-10'>

			<Helmet>
				<title>Электромонтажные работы | ООО «АмперСистемс»</title>
				<meta
					name="description"
					content="ООО «АмперСистемс» предлагает профессиональные услуги по электромонтажным работам. Наши специалисты обладают богатым опытом и готовы выполнить любые электромонтажные работы, от установки электропроводки до монтажа электрооборудования. Доверьте свои проекты нам и получите гарантированное качество и надежность."
				/>
			</Helmet>


			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Электромонтажные работы любой сложности
				</h1>

				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>

					{/* <div className='sd:w-1/2 xz:w-full sd:mr-6 xz:mr-0 sd:mt-0 xz:mt-8'>
						<p className='sd:text-lg xz:text-base'>
							Наша организация готова оказать услуги по производству электромонтажных работ на Вашем объекте от установки розеток до строительства трансформаторных подстанций, воздушных и кабельных линий электропередач напряжением 0,4-10 кВ.
						</p>
						<p className='sd:text-lg xz:text-base'>
							В нашем расположении имеется вся необходимая техника и оборудование. Наши специалисты обучены качественным и безопасным методам производства работ.
						</p>
					</div> */}

					<div className='sd:w-1/2 xz:w-full'>
						<ul className='sd:text-xl xz:text-lg mt-8'>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж счетчиков электрической энергии' className='' style={{ width: '25px' }} />
								<p className='ml-3'>
									Монтаж счетчиков электрической энергии
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж электрического отопления' className='w-6' />
								<p className='ml-3'>
									Монтаж электрического отопления
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж проводки в квартирах, жилых домах' className='w-6' />
								<p className='ml-3'>
									Монтаж проводки в квартирах, жилых домах
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж электрических сетей на промышленных объектах' className='w-6' />
								<p className='ml-3'>
									Монтаж электрических сетей на промышленных объектах
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Монтаж, ремонт уличного освещения' className='w-6' />
								<p className='ml-3'>
									Монтаж, ремонт уличного освещения
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Прокладка и ремонт кабельных линий 0,4 – 10кВ' className='w-6' />
								<p className='ml-3'>
								Прокладка и ремонт кабельных линий 0,4 – 10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Прокладка и ремонт воздушных линий 0,4 – 10кВ' className='w-6' />
								<p className='ml-3'>
								Прокладка и ремонт воздушных линий 0,4 – 10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Замена Ж/Б опор' className='w-6' />
								<p className='ml-3'>
								Замена Ж/Б опор
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Фазировка КЛ, ВЛ 0,4 – 10кВ' className='w-6' />
								<p className='ml-3'>
								Фазировка КЛ, ВЛ 0,4 – 10кВ
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Замена изоляторов, разъединителей, траверс' className='w-6' />
								<p className='ml-3'>
								Замена изоляторов, разъединителей, траверс
								</p>
							</li>
						</ul>

					</div>

					<div className='sd:w-1/2 xz:w-full sd:ml-6 xz:ml-0'>
						<Image src={img} alt='Электромонтажные работы' preview={false} className='rounded-md shadow-xl' />
					</div>
				</div>

				{/* 
				<div className="overflow-x-auto mt-20">

					<h2 className='sd:text-5xl xz:text-3xl text-center'>
						Стоимость электромонтажных работ
					</h2>
					<table className="min-w-full bg-white border border-gray-300">
						<thead>
							<tr>
								<th className="py-2 px-4 border-b">Вид работ</th>
								<th className="py-2 px-4 border-b">Ед. изм.</th>
								<th className="py-2 px-4 border-b">Сумма у.е.</th>
							</tr>
						</thead>
						<tbody>
							{data.map((item, index) => (
								<tr key={index}>
									<td className="py-2 sd:px-4 xz:px-2 sd:text-base xz:text-sm border-b">{item.work}</td>
									<td className="py-2 sd:px-4 xz:px-2 sd:text-base xz:text-sm border-b">{item.unit}</td>
									<td className="py-2 sd:px-4 xz:px-2 border-b">{item.cost}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div> */}

				<Info />


				<BtnComp name='Итересует стоимость и сроки электромонтажных работ' />
			</section>
		</main>
	)
}

export default Elektromontazh