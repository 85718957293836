import React, { useState } from 'react'
import { Image, Affix } from 'antd'
import logo from '../../../images/logo/logo3.webp'
import { ClockCircleOutlined, PhoneOutlined, ShakeOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useScreens } from '../../../constants/Constants'
import { DrawerComp } from '../../drawer/DrawerComp'
import { motion } from "framer-motion"
import { yAnimation3 } from '../../../constants/animation/AnimationConst'
import mts from '../../../images/mts.svg'
import { Link, useLocation } from 'react-router-dom'

export const HeaderInfo = ({ setIsVisible }) => {
	const screens = useScreens()
	const location = useLocation();
	const currentPath = location.pathname;
	const [open, setOpen] = useState(false)
	const showDrawer = () => {
		setOpen(true)
	}

	function gtag_report_conversion(url) {
		var callback = function () { };
		window.gtag('event', 'conversion', {
			'send_to': 'AW-11150470445/41XrCJTP0f0DEK3a-sQp',
			'event_callback': callback
		});
		return false;
	}

	return (
		<>
			{
				screens.md
					?
					<div className='bg-white relative z-50 py-0.5'>
						<div className='container mx-auto flex justify-between items-center'>
							<div className='flex justify-start items-center'>
								<Image src={logo} preview={false} width='220px' />
							</div>


							<div className='font-light text-[#000]'>
								<div className='flex text-xl items-center '>
									<ClockCircleOutlined />
									<span className='uppercase ml-2  mr-0.5'>9:00-18:00</span>
								</div>

								<span className='mt-1 text-sm pl-2'>Выходной: </span><span className='text-red-500'>сб-вс</span>
							</div>


							<div className=''>
								<div className='flex text-lg text-[#000]'>
									<PhoneOutlined className='rotate-90 mr-3 text-2xl' />
									<div className='flex items-center'>
										<a href='tel:80297777125' className='text-2xl mr-1'>+375 29 7777-125</a>
										<Image src={mts} alt='Оператор МТС' className='' width={65} height={20} />
									</div>
								</div>
							</div>


						</div>
					</div>
					:
					<motion.div
						initial="hidden"
						whileInView="visible"
					>
						<motion.div
							className={`flex flex-col 
						justify-between items-center
						 text-[#0CDB74] pt-20 pb-3 
						 relative z-10 ${currentPath === '/' ? 'backdrop-blur-sm' : 'bg-gray-800'}`}
							variants={yAnimation3}
						>
							<div className='text-sm'>
								<div className='flex text-lg items-center'>
									<ClockCircleOutlined />
									<span className='uppercase ml-2 font-semibold mr-0.5'>9:00-18:00</span>
								</div>
								<span className='text-white'>Выходной: </span>
								<span className='uppercase ml-0.5 font-semibold text-red-500'>сб-вс</span>
							</div>
							<div className='mt-2 mb-2'>
								<div className='text-lg '>
									<div className='flex items-center pl-3 text-white'>
										<a
											href='tel:80297777125'
											onClick={() => gtag_report_conversion('/')}
											className='text-2xl'
										>
											+375 29 7777-125

										</a>
										<Image src={mts} alt='Оператор МТС' className='ml-1.5' width={70} height={20} />
									</div>
								</div>
							</div>

							<div className='flex flex-col items-center mt-3'>
								<p className='text-sm mb-2 uppercase text-yellow-500' onClick={() => showDrawer('top', 'Заказать звонок')}>
									<ShakeOutlined /> Заказать звонок</p>
							</div>

							{/* <div className='relative'>
								<Button type='primary' className='mt-3' style={{ backgroundColor: '#fff' }}>
									<a href='tel:80293613612'>
										<PhoneOutlined className='rotate-90 animate-pulse text-red-600' />
										<span className='uppercase text-red-600'>
											{' '}Аварийная бригада 24/7
										</span>
									</a>
								</Button>
								<Tooltip placement="topRight" title='Выездная оперативная круглосуточная аварийная служба по ремонту электрооборудования.'>
									<QuestionCircleOutlined className='absolute top-5 -right-8 text-[#0CDB74]' />
								</Tooltip>
							</div> */}

							<Affix offsetTop={30} onChange={() => setIsVisible(i => !i)}><div></div></Affix>
						</motion.div>
					</motion.div>
			}
			<DrawerComp open={open} placement={'top'} title={'Заказать звонок'} setOpen={setOpen} isActiveForm={{ tel: true }} />
		</>
	)
}
