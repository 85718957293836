
import React from 'react'
import img from '../../../images/services/5.webp'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'

const PodklyuchElectrich = () => {

	return (
		<main className='sd:py-16 xz:py-10'>

			<Helmet>
				<title>Подключение к сети электроснабжения под ключ | ООО «АмперСистемс»</title>
				<meta
					name="description"
					content="ООО «АмперСистемс» предоставляет полный комплекс услуг по подключению к сети электроснабжения 'под ключ'. Мы обеспечиваем профессиональное выполнение всех необходимых работ, включая разработку проекта, получение разрешительной документации, проведение монтажных и пуско-наладочных работ. Наши специалисты гарантируют высокое качество и надежность проведенных работ, а также соблюдение всех требований и стандартов безопасности."
				/>
			</Helmet>

			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Комплекс услуг по подключению к сети электроснабжения под ключ
				</h1>

				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>

					<div className='sd:w-1/2 xz:w-full sd:mr-6 xz:mr-0 sd:mt-0 xz:mt-8'>
						<p className='sd:text-lg xz:text-base'>
							Главным плюсом нашей организации является комплекс услуг по подключению к сети электроснабжения под ключ. Заказывая данную услугу, заказчик избавляет себя от всех проблем, связанных с подключением объекта строительства к сети электроснабжения. Все специалисты работают в одной организации, что позволяет избежать недопонимания между заказчиком, проектировщиком, подрядчиком и контролирующими организациями.
						</p>
					</div>

					<div className='sd:w-1/2 xz:w-full sd:ml-6 xz:ml-0'>
						<Image src={img} alt='Электромонтажные работы' preview={false} className='rounded-md shadow-xl' />
					</div>
				</div>

				<Info />
				<BtnComp name='Итересует стоимость и сроки подключения к сети электроснабжения' />
			</section>

		</main>
	)
}

export default PodklyuchElectrich