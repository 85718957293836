import React from 'react'
import { Helmet } from "react-helmet"

const About = () => {
	return (
		<main className='min-h-screen sd:py-14 xz:py-10'>
			<Helmet>
				<title>{'О компании ООО АмперСистем'}</title>
				<meta name="description" content={'Информация о компании ООО АмперСистем: история, миссия, ценности, услуги и контакты.'} />
				<meta name="keywords" content={'ООО АмперСистем, о компании, история, миссия, ценности, услуги, контакты'} />
				<meta property="og:title" content={'О компании ООО АмперСистем'} />
				<meta property="og:description" content={'Информация о компании ООО АмперСистем: история, миссия, ценности, услуги и контакты.'} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://ampersystem.by/o-kompanii" />
				<meta property="og:image" content="" />
				<meta name="twitter:card" content="" />
				<meta name="twitter:title" content={'О компании ООО АмперСистем'} />
				<meta name="twitter:description" content={'Информация о компании ООО АмперСистем: история, миссия, ценности, услуги и контакты.'} />
				<meta name="twitter:image" content="" />
			</Helmet>

			<section className=''>
				<div className='container mx-auto'>

					<h1 className='sd:text-5xl xz:text-3xl text-center font-bold'>
						О компании
					</h1>
					<article className='sd:mt-12 xz:mt-6'>

						<p className='mb-4'>
							ООО "АмперСистем" – ведущая компания в сфере электроснабжения, предоставляющая комплексные инженерные решения и услуги на территории Российской Федерации. С момента основания, наша компания зарекомендовала себя как надежный партнер, способный справляться с задачами любой сложности в области проектирования, монтажа и обслуживания электроустановок.
						</p>

						<h2 className='sd:text-2xl xz:text-xl font-semibold'>
							Наша миссия
						</h2>
						<p className='mt-3'>
							Мы стремимся обеспечивать стабильное и безопасное электроснабжение для наших клиентов, предоставляя профессиональные услуги высокого качества. Наша цель – создать надежные и эффективные решения, способствующие развитию бизнеса и улучшению качества жизни.
						</p>

						<h3 className='sd:text-2xl xz:text-xl font-semibold'>
							Наши ценности
						</h3>
						<ul className='mt-4'>
							<li className='mb-2'>
								<span className='font-bold'>• Профессионализм:</span> Команда ООО "АмперСистем" состоит из высококвалифицированных специалистов с многолетним опытом работы в отрасли. Мы постоянно повышаем свою квалификацию, чтобы соответствовать самым высоким стандартам.
							</li>
							<li className='mb-2'>
								<span className='font-bold'>• Качество и надежность:</span>  Мы используем только проверенные и сертифицированные материалы и оборудование, что гарантирует долгий срок службы и бесперебойную работу наших систем.
							</li>
							<li className='mb-2'>
								<span className='font-bold'>• Индивидуальный подход:</span>  Мы учитываем все пожелания и требования клиентов, предлагая оптимальные решения, адаптированные под конкретные условия и задачи.
							</li>
							<li className='mb-2'>
								<span className='font-bold'>• Безопасность:</span> Соблюдение всех норм и стандартов безопасности является нашим приоритетом на всех этапах работы, от проектирования до эксплуатации.
							</li>
						</ul>

						<h4 className='sd:text-xl xz:text-lg mt-5 font-semibold'>
							Наши достижения
						</h4>

						<ul className='mt-4'>
							<li className='mb-2'>
								• Успешное выполнение множества проектов различной сложности для частных лиц, коммерческих организаций и государственных учреждений.
							</li>
							<li className='mb-2'>
								• Налаженные партнерские отношения с ведущими поставщиками оборудования и материалов, что позволяет нам предлагать конкурентоспособные цены и высокое качество услуг.
							</li>
							<li className='mb-2'>
								• Постоянное расширение спектра услуг и улучшение технологической базы для обеспечения комплексного подхода к решению задач наших клиентов.
							</li>
						</ul>

						<h5 className='sd:text-xl xz:text-lg mt-5'>
							Перспективы развития
						</h5>
						<p className=''>
							ООО "АмперСистем" продолжает развиваться и совершенствоваться, внедряя инновационные технологии и методы работы. Мы активно следим за новыми тенденциями и решениями в области электроснабжения, чтобы предлагать нашим клиентам самые современные и эффективные решения.
						</p>

						<p className='mt-7 font-semibold'>
							ООО "АмперСистем" – ваш надежный партнер в области электроснабжения. Мы гарантируем высокое качество, надежность и безопасность всех выполняемых работ. Наш опыт и профессионализм являются залогом успешного выполнения любых задач в области электротехники.
						</p>
					</article>
				</div>
			</section>
		</main>
	)
}

export default About