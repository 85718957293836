import React from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import img1 from '../../images/services/1.webp';
import img2 from '../../images/services/2.webp';
import img3 from '../../images/services/3.webp';
import img4 from '../../images/services/4.webp';
import img5 from '../../images/services/5.webp';
import img6 from '../../images/services/6.webp';
import img7 from '../../images/service/13.webp';
import img8 from '../../images/service/1.webp';
import img9 from '../../images/service/8.webp';

const servicesData = [
	{
		title: `Инжиниринговые услуги`,
		image: img1,
		link: '/uslugi/inzhiniringovye-uslugi'
	},
	{
		title: 'Проектирование электроснабжения',
		image: img2,
		link: '/uslugi/proektirovanie-elektrosnabzheniya'
	},
	{
		title: 'Электромонтажные работы любой сложности',
		image: img3,
		link: '/uslugi/elektromontazhnye-raboty'
	},
	{
		title: 'Электрофизические измерения',
		image: img4,
		link: '/uslugi/elektrofizicheskie-izmereniya'
	},
	{
		title: 'Комплекс услуг по подключению к сети электроснабжения под ключ',
		image: img5,
		link: '/uslugi/podklyuchenie-elektrichestva'
	},
	{
		title: 'Электрическое отопление',
		image: img6,
		link: '/uslugi/elektricheskoe-otoplenie'
	},
	{
		title: 'Обслуживание электроустановок 0,4-10кВ, ответственный за электрохозяйство',
		image: img7,
		link: '/uslugi/obsluzhivanie-elektroustanovok'
	},
	{
		title: 'Монтаж трансформаторных подстанций',
		image: img8,
		link: '/uslugi/montazh-transformatornyh-podstancij'
	},
	{
		title: 'Монтаж воздушных, кабельных линий электропередач 0,4 – 10кВ',
		image: img9,
		link: '/uslugi/montazh-vozdushnyh-kabelnyh-linij'
	},
];

export const Services = () => {
	return (
		<section className='sd:py-16 xz:py-10 overflow-x-hidden relative bg-white'>
			<div className='container mx-auto'>
				<h2 className='sd:text-5xl xz:text-3xl uppercase text-center'>
					Наши услуги
				</h2>
				<div className='grid sd:grid-cols-3 xz:grid-cols-1 xy:grid-cols-2 sd:gap-4 xz:gap-3 mt-8'>
					{servicesData.map((service, index) => (
						<Link to={service.link} key={index} className="flex">
							<div className={`border-2 border-black hover:scale-105 transition-transform duration-500 bg-white sd:p-3 xy:p-2 text-center overflow-hidden flex flex-col justify-between shadow-lg hover:shadow-2xl`}>
								<Image src={service.image} alt={service.title} preview={false} className='' />
								<div className='mt-3 h-full flex justify-center items-center xz:py-3 xy:py-0'>
									<h3 className='font-bold sd:text-xl xz:text-base xy:text-sm hover:underline sd:px-2 xy:px-0'>
										{service.title.split('.').map((line, idx) => (
											<React.Fragment key={idx}>
												{line}
												<br />
											</React.Fragment>
										))}
									</h3>
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		</section>
	);
};
