
import React from 'react'
import img from '../../../images/services/1.webp'
import { Image } from 'antd'
import { Helmet } from 'react-helmet'
import BtnComp from '../../../components/btn/BtnComp'
import { Info } from '../../../components/info/Info'
import check from '../../../images/check3.svg'

const InzhinUslugi = () => {

	return (
		<main className='sd:py-16 xz:py-10'>

			<Helmet>
				<title>Инжиниринговые услуги | ООО «АмперСистемс»</title>
				<meta
					name="description"
					content="Наши инжиниринговые услуги предоставляют вам возможность получить высококачественное проектирование, монтаж и обслуживание электроустановок. Обратитесь к нам для решения ваших электротехнических задач."
				/>
			</Helmet>


			<section className='container mx-auto'>
				<h1 className='sd:text-5xl xz:text-3xl font-bold'>
					Инжиниринговые услуги
				</h1>

				<div className='flex sd:flex-row xz:flex-col-reverse sd:mt-14 xz:mt-7'>

					{/* <div className='sd:w-1/2 xz:w-full sd:mr-6 xz:mr-0 sd:mt-0 xz:mt-8'>
						<p className='sd:text-lg xz:text-base'>
							Наша организация готова оказать услуги по обследованию объекта, составлению технического задания, получения разрешительной документации на объект электроснабжения, а также работы по подготовке комплекта документации для предоставления в уполномоченные организации в целях подключения инженерных сетей (сетей электроснабжения) и осуществления сопровождения в процессе приемо-сдаточных работ уполномоченным организациям.
						</p>
						<p className='sd:text-lg xz:text-base'>
							Преимуществом данной услуги является экономия времени и денежных средств заказчика. Наши специалисты подберут для Вас самый короткий и дешевый путь для электроснабжения Вашего объекта. При необходимости мы представим Ваши интересы в государственных организациях и сделаем необходимые расчеты электрической сети для определения возможности подключения.
						</p>
					</div> */}

					<div className='sd:w-1/2 xz:w-full'>
						<ul className='sd:text-xl xz:text-lg mt-8'>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Получение технических условий на электроснабжение' className='' style={{ width: '25px' }} />
								<p className='ml-3'>
									Получение технических условий на электроснабжение
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Получение разрешения на проведение земляных работ' className='w-6' />
								<p className='ml-3'>
									Получение разрешения на проведение земляных работ (ордер)
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Получение отвода земли' className='w-6' />
								<p className='ml-3'>
									Получение отвода земли
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Подготовке комплекта документации' className='w-6' />
								<p className='ml-3'>
									Подготовке комплекта документации для предоставления в уполномоченные организации в целях подключения инженерных сетей (сетей электроснабжения)
								</p>
							</li>
							<li className='mb-2 flex items-start'>
								<img src={check} alt='Осуществление сопровождения в процессе приемо-сдаточных работ' className='w-6' />
								<p className='ml-3'>
									Осуществление сопровождения в процессе приемо-сдаточных работ уполномоченным организациям
								</p>
							</li>
						</ul>

					</div>


					<div className='sd:w-1/2 xz:w-full sd:ml-10 xz:ml-0'>
						<Image src={img} alt='Инжиниринговые услуги' preview={false} className='rounded-md shadow-xl' />
					</div>
				</div>


				<Info />
				<BtnComp name='Итересует стоимость и сроки инжиниринговых услуг' />
			</section>
		</main>
	)
}

export default InzhinUslugi