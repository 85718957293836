import React, { useState } from 'react'
import { ModalComp } from '../modal/ModalComp'
import { Button } from 'antd'

const BtnComp = ({name , title2 = 'Узнать стоимость и сроки'}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [title, setTitle] = useState('')

	const showModal = (title) => {
		setIsModalOpen(true)
		setTitle(title)
	}

	const handleCancel = () => {
		setIsModalOpen(false)
	}
	return (
		<>
			<Button
				// type="primary"
				size='large'
				onClick={() => showModal(name)}
				className='cursor-pointer uppercase'
			>
				<span className='text-black'>{title2}</span>
			</Button>

			<ModalComp question={true} isModalOpen={isModalOpen} title={title} handleCancel={handleCancel} />
		</>
	)
}

export default BtnComp