import './App.css'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import React, { createContext, useState } from 'react'
import { ConfigProvider } from 'antd'
import BreadCrumbComp from './components/breadcrumb/BreadcrumbComp'
import { FooterComp } from './components/footer/FooterComp'
import Scroll from './hoc/Scroll'
import DataStore from './store/DataStore'
import ErrorPage from './pages/errorPage/ErrorPage'
import locale from 'antd/es/locale/ru_RU'
import { Header } from './components/header/Header'
import { MainPage } from './pages/mainPage/MainPage'
import { StyleProvider } from '@ant-design/cssinjs'
import 'antd/dist/reset.css'
import { ContactPage } from './pages/contactPage/ContactPage'
import InzhinUslugi from './pages/uslugi/inzhiniringovye-uslugi/InzhinUslugi'
import ProektElectro from './pages/uslugi/proektirovanie-elektrosnabzheniya/ProektElectro'
import Elektromontazh from './pages/uslugi/elektromontazhnye-raboty/Elektromontazh'
import ElektroIzmeren from './pages/uslugi/elektrofizicheskie-izmereniya/ElektroIzmeren'
import PodklyuchElectrich from './pages/uslugi/podklyuchenie-elektrichestva/PodklyuchElectrich'
import ElektrichOtopl from './pages/uslugi/elektricheskoe-otoplenie/ElektrichOtopl'
import About from './pages/o-kompanii/About'
import ObsluzhivElektroustanovok from './pages/uslugi/obsluzhivanie-elektroustanovok/ObsluzhivElektroustanovok'
import MontazhTransformPodstancij from './pages/uslugi/montazh-transformatornyh-podstancij/MontazhTransformPodstancij'
import MontazhVozdushKabelLinij from './pages/uslugi/montazh-vozdushnyh-kabelnyh-linij/MontazhVozdushKabelLinij'
import ArendaMiniEkskavatora from './pages/arendaMiniEkskavatoraPage/ArendaMiniEkskavatora'

export const Context = createContext(null)



function App() {
  const [dataApp] = useState(new DataStore())
  const [hover, setHover] = useState(false)

  const handleMouseLeave = () => {
    setHover(false)
  }

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          // colorPrimary: '#05d3ac',
          // colorLink: '#05d3ac',
          colorPrimary: '#ffffff',
          colorLink: '#ffffff',
        },
      }}
    >
      <Context.Provider
        value={{
          dataApp,
        }}
      >
        <StyleProvider hashPriority="high">
          <BrowserRouter>
            <div className="app bg-white">
              <div >
                <Header setHover={setHover} hover={hover} />
                <main className=''
                  onMouseEnter={handleMouseLeave}
                >
                  <BreadCrumbComp />
                  <Scroll>
                    <Routes>
                      <Route path='/' element={<MainPage />} />
                      {/* <Route path='/uslugi/:link' element={<ServicePage />} /> */}
                      <Route path='/uslugi/inzhiniringovye-uslugi' element={<InzhinUslugi />} />
                      <Route path='/uslugi/proektirovanie-elektrosnabzheniya' element={<ProektElectro />} />
                      <Route path='/uslugi/elektromontazhnye-raboty' element={<Elektromontazh />} />
                      <Route path='/uslugi/elektrofizicheskie-izmereniya' element={<ElektroIzmeren />} />
                      <Route path='/uslugi/podklyuchenie-elektrichestva' element={<PodklyuchElectrich />} />
                      <Route path='/uslugi/elektricheskoe-otoplenie' element={<ElektrichOtopl />} />
                      <Route path='/uslugi/obsluzhivanie-elektroustanovok' element={<ObsluzhivElektroustanovok />} />
                      
                      <Route path='/uslugi/montazh-transformatornyh-podstancij' element={<MontazhTransformPodstancij />} />
                      <Route path='/uslugi/montazh-vozdushnyh-kabelnyh-linij' element={<MontazhVozdushKabelLinij />} />
                      
                      <Route path='/uslugi' element={<Navigate to='/' />} />
                      <Route path='/o-kompanii' element={<About />} />
                      <Route path='/arenda-mini-ekskavatora' element={<ArendaMiniEkskavatora />} />
                      <Route path='/kontakty' element={<ContactPage />} />
                      <Route path='*' element={<ErrorPage />} />
                    </Routes>
                  </Scroll>
                </main>
              </div>
              <FooterComp />
            </div>
          </BrowserRouter>
        </StyleProvider>
      </Context.Provider>
    </ConfigProvider>
  );
}

export default App
